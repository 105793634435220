<template>
	<div style="background: #fff;padding: 15px;">
		<div>
			<div class="filter-container">
				<div class="filter-item">
						<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
						<el-select v-model="keyWordsType" style='width:100px;margin-right:10px;'>
							<el-option label="推手" :value="0"></el-option>
							<el-option label="订单" :value="1"></el-option>
						</el-select>
						<el-input v-model="searchKey" :placeholder="keyWordsType==0? '手机号、微信昵称、姓名': '订单编号'" style="width:200px;margin-right:10px"></el-input>
						
						<label class="label" style="width: 70px;white-space: nowrap;">收益时间: </label>
						<el-date-picker v-model="startTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"  default-time="00:00:00"
							placeholder="开始时间">
						</el-date-picker>
						<span style="margin: 0 10px;">~</span>
						<el-date-picker v-model="endTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59"
							placeholder="结束时间">
						</el-date-picker>
				</div>
        
				<div class="filter-item">
						<label class="label" style="white-space: nowrap;">推手级别: </label>
						<el-select v-model="agentRoleId">
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in agentRoleSelectList" :key="item.Value" :label="item.Text" :value="item.Value">
							</el-option>
						</el-select>
				</div>

				<div class="filter-item">
						<label class="label" style="white-space: nowrap;">收益类型: </label>
						<el-select v-model="recordType" >
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in recordTypeList" :key="item.id" :label="item.label" :value="item.id">
							</el-option>
						</el-select>
				</div>

				<div class="filter-item">
						<label class="label" style="white-space: nowrap;">收益状态: </label>
						<el-select v-model="recordState" >
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in recordStateList" :key="item.id" :label="item.label" :value="item.id">
							</el-option>
						</el-select>
						
						<el-button type="primary" style="margin-left: 15px;width: 90px;" @click="goodsSearch">查询</el-button>
						<el-button style="margin-left: 15px;width: 90px;" @click="exportlist">导出</el-button>
				</div>
				
			</div>

			<!-- 门店管理表格 -->
			<div class="table-container">
        <div class="totalStyle">
          <div>累计收益（元）：{{awardMoneys.Total}}</div>
          <div class='award-detail'>
            <span>待结算收益（元）：{{awardMoneys.UnfinishBalance}}</span>
            <span>已结算收益（元）：{{awardMoneys.FinishBalance}}</span>
            <span>已扣除收益（元）：{{awardMoneys.CancelBalance}}</span>
          </div>
        </div>
				<el-table :data="tableList" style="width: 100%;" v-loading="loading">
					<el-table-column label="推手" width="220px">
						<template slot-scope="scope">
							<div style="align-items: center;">
                <div class="table-name">{{scope.row.NameDisplay}}</div>
                <div>{{scope.row.Phone}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AgentRoleName" label="推手级别"></el-table-column>
					<el-table-column prop="AddTime" label="收益时间"></el-table-column>
					<el-table-column prop="AwardMoney" label="收益（元）"></el-table-column>
					<el-table-column prop="StateDisplay" label="收益状态"></el-table-column>
					<el-table-column label="订单" width="200px">
            <template slot-scope="scope">
              <el-button type='text' style="width:180px" @click='openWindowOrder(scope.row)'>{{scope.row.OrderNo}}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="商品" width="220px">
            <template slot-scope="scope">
              <div class='name-content'>{{scope.row.ProductName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ProductCount" label="购买数量"></el-table-column>
          <el-table-column prop="AwardSingleMoneyDisplay" label="收益比"></el-table-column>
          <el-table-column label="收益备注" width="220px">
            <template slot-scope="scope">
							<el-popover
								placement="top-end" width="300" trigger="hover"
								:content="scope.row.Remark">
								<div class='name-content' slot="reference">{{scope.row.Remark}}</div>
							</el-popover>
            </template>
          </el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div></div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other.js'
	export default {
		data() {
			return {
        keyWordsType: 0,
				loading: false,
				awardMoneys:{
					Total: 0,
					CancelBalance: 0,
					UnfinishBalance: 0,
					FinishBalance: 0,
				},

				searchKey: '',
				startTime: '',
				endTime: '',
				agentRoleId: null,
				agentRoleSelectList: [],
				recordState: null,
				recordStateList: [
					{
						id: 0,
						label: '待结算'
					},
					{
						id: 1,
						label: '已结算'
					},
					{
						id: 2,
						label: '已失效'
					}
				],
				recordType: null,
				recordTypeList: [
					{
						id: 200,
						label: '自营零售收益'
					},
					{
						id: 201,
						label: '直推销售收益'
					},
					{
						id: 202,
						label: '间推销售收益'
					},
					{
						id: 203,
						label: '团队收益'
					},
				],
				currentPage: 1,
				pageSize: 20,
				Total: 0,

				tableList: [],

				imgUrl: config.IMG_BASE,
				defaultHeader:config.DEFAULT_HEADER,
        exportUrl: config.EXPORT_URL,
			};
		},
		beforeMount() {
			this.searchKey = this.$route.query.phone||'';
			this.initTime()
			this.getListData()
			this.getAgentRoleList()
		},
		methods: {
			initTime(){
				let date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
				let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				let minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				let second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				this.startTime = year + "-" + month + "-" + "01" + " " + "00:00:00";
				this.endTime = year + "-" + month + "-" + day + " " + hour+ ":" + minute+ ":" + second;
			},
			async getListData() {
				this.loading = true
				try {
					let data = {
            KeywordsType: this.keyWordsType,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
						Keywords: this.searchKey,
						AgentRoleId: this.agentRoleId,
            CommissionRecordState: this.recordState,
            CommissionRecordType: this.recordType,
						StartTime: this.startTime,
						EndTime: this.endTime
					}
					let result = await apiList.agentDetail(data)
					
					this.awardMoneys = {
						Total: (result.Result.FinishBalance + result.Result.UnfinishBalance).toFixed(2),
						UnfinishBalance: result.Result.UnfinishBalance.toFixed(2),
						FinishBalance: result.Result.FinishBalance.toFixed(2),
						CancelBalance: result.Result.CancelBalance.toFixed(2),
					}

					this.tableList = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
			async  getAgentRoleList() {
				try {
					let result = await apiList.agentInit()
					this.agentRoleSelectList = result.Result.AgentRoleSelectList
				} catch (e) {
				}
			},
      openWindowOrder(row){
        let url = (row.OrderType==0?'/order/orderDetailpage':'/videoShop/videoOrderDetails')+'?Id=' + row.OrderNo
				this.$common.openNewWindow(url)
      },
			goodsSearch() {
				this.currentPage = 1
				this.getListData()
			},
			async exportlist(){
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/commission/agent/detail-export?' +
						'&KeyWordsType=' + this.keyWordsType +
						'&Keywords=' + this.searchKey +
						'&StartTime=' + (this.startTime ? this.startTime: '' ) +
						'&EndTime=' + (this.endTime ? this.endTime: '' ) +
						'&AgentRoleId=' + (this.agentRoleId ?  this.agentRoleId: '' )+
						'&CommissionRecordState=' + (this.recordState? this.recordState: '') +
						'&CommissionRecordType=' + (this.recordType? this.recordType: '')
					window.open(url);
					console.log("url",url)
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getListData();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getListData();
			},
		},
	}
</script>

<style lang="less" scoped>
.table-container{
  .name-content{
    width:200px;
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */ 
    -webkit-box-orient: vertical;
  }
  .totalStyle {
    background: #f5f5f5;
    padding: 13px;
    font-size: 15px;
    .award-detail{
      margin-top: 10px;
      display:inline-block;
      color: #909399;
      font-size: 13px;
      span{
        margin-right:50px;
      }
    }
  }
  .table-img{
    width: 50px !important;
    object-fit: scale-down;
    border-radius: 100%;
  }
  .table-name{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
